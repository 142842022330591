<template>
  <div id="commonPay">
    <div>
      <!-- 顶部NavBar 开始 -->
      <div class="head">
        <van-nav-bar
          title="电费"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div class="outboxstyle">
      <!-- 表单面板 -->
      <div class="boxstyle">
        <div class="title">
          <van-icon name="column" />
          <span>缴费信息</span>
        </div>
        <div class="box-form">
          <van-field
            readonly
            clickable
            name="picker"
            :value="form.school"
            label="校区"
            placeholder="请选择校区"
            input-align="right"
            @click="schoolFlag = true"
          />
          <van-popup v-model="schoolFlag" position="bottom">
            <van-picker
              show-toolbar
              :columns="school"
              @confirm="onConfirmSchool"
              @cancel="schoolFlag = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="picker"
            :value="form.building"
            label="楼栋"
            placeholder="请选择楼栋"
            input-align="right"
            @click="buildingFlag = true"
          />
          <van-popup v-model="buildingFlag" position="bottom">
            <van-picker
              show-toolbar
              :columns="building"
              @confirm="onConfirmBuilding"
              @cancel="buildingFlag = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="picker"
            :value="form.floor"
            label="楼层"
            placeholder="请选择楼层"
            input-align="right"
            @click="floorFlag = true"
          />
          <van-popup v-model="floorFlag" position="bottom">
            <van-picker
              show-toolbar
              :columns="floor"
              @confirm="onConfirmFloor"
              @cancel="floorFlag = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="picker"
            :value="form.room"
            label="房间"
            placeholder="请选择房间"
            input-align="right"
            @click="roomFlag = true"
          />
          <van-popup v-model="roomFlag" position="bottom">
            <van-picker
              show-toolbar
              :columns="room"
              @confirm="onConfirmRoom"
              @cancel="roomFlag = false"
            />
          </van-popup>
          <van-cell title="剩余电费" :value="form.remain" />
        </div>
      </div>
      <div class="boxstyle">
        <div class="title">
          <van-icon name="bill" />
          <span>缴费金额</span>
        </div>
        <div class="check-box">
          <div class="position-rel" :class="{'checked':form.chooseRadio==50}" @click="chooseRadio(50)">
            ￥50
            <img v-if="form.chooseRadio==50" src="@/assets/newImages/right.png">
          </div>
          <div class="position-rel" :class="{'checked':form.chooseRadio==100}" @click="chooseRadio(100)">
            ￥100
            <img v-if="form.chooseRadio==100" src="@/assets/newImages/right.png">
          </div>
          <div class="position-rel" :class="{'checked':form.chooseRadio==200}" @click="chooseRadio(200)">
            ￥200
            <img v-if="form.chooseRadio==200" src="@/assets/newImages/right.png">
          </div>
        </div>
        <div class="position-rel width-100" :class="{'checked':form.custom}">
          <van-field v-model="form.custom" label-width="120" :class="{'checked2':form.custom}" placeholder="自定义金额" type="number" input-align="center" @focus="clearRadio" @blur="strJoin" />
          <img v-if="form.custom" src="@/assets/newImages/right.png">
        </div>
      </div>
    </div>
    <div class="bottom">
      <van-button type="info" size="large" class="bottombutton" @click="next">{{ $t('main.next') }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonPay',
  data() {
    return {
      school: ['学校1', '宁波', '温州', '嘉兴', '湖州'],
      building: ['楼栋1', '宁波', '温州', '嘉兴', '湖州'],
      floor: ['楼层1', '宁波', '温州', '嘉兴', '湖州'],
      room: ['房间1', '宁波', '温州', '嘉兴', '湖州'],
      form: {
        school: '', // 校区
        building: '', // 楼栋
        floor: '', // 楼层
        room: '', // 房间
        chooseRadio: '', // 单选金额
        custom: '', // 自定义金额
        remain: '--' // 剩余电费
      },
      schoolFlag: false, // 校区选择器开关
      buildingFlag: false, // 楼栋选择器开关
      floorFlag: false, // 楼层选择器开关
      roomFlag: false // 房间选择器开关

    }
  },
  watch: {
    form: {
      handler: 'inputCustom',
      deep: true
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
  },
  methods: {
    // 选择学校
    onConfirmSchool(value) {
      this.form.school = value
      this.schoolFlag = false
    },
    // 选择楼栋
    onConfirmBuilding(value) {
      this.form.building = value
      this.buildingFlag = false
    },
    // 选择楼层
    onConfirmFloor(value) {
      this.form.floor = value
      this.floorFlag = false
    },
    // 选择房间
    onConfirmRoom(value) {
      this.form.room = value
      this.roomFlag = false
    },
    // 顶部返回
    onClickLeft() {
      this.$router.push({ path: '/home' })
    },
    // 选择默认金额
    chooseRadio(num) {
      this.form.chooseRadio = num
      this.form.custom = ''
    },
    // 输入自定义金额
    inputCustom(val) {
      if (val.custom !== '') {
        this.form.chooseRadio = ''
      }
    },
    // 自定义金额失去焦点拼接￥
    strJoin() {
      if (this.form.custom) {
        this.form.custom = '￥' + parseFloat(this.form.custom)
      }
    },
    // 自定义金额获取焦点清除预选金额
    clearRadio() {
      this.form.custom = ''
      this.form.chooseRadio = ''
    },
    // 提交表单
    next() {
      alert(this.form.custom || this.form.chooseRadio)
    }
  }
}
</script>
<style scoped lang="scss">
#commonPay {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 100px;
    width: 100%;
    background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 10px 12px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
  .title{
    color: #2F9FF2;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span{
      color: #000;
      font-size: 14px;
      margin: 2px 5px 0;
    }
  }
  .check-box{
    display: flex;
    justify-content: space-between;
  }
  .position-rel{
    width: 85px;
    height: 40px;
    font-size: 14px;
    color: #D0D0D0;
    text-align: center;
    line-height: 40px;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    position: relative;
    img{
      position: absolute;
      right: 0px;
      top: 0px;
      width: 20px;
    }
    ::after{
      border: 0;
    }
  }
  .checked{
    border: 1px solid #2F9FF2;
    color: #F86262;
  }
  .checked2{
    ::v-deep input{
      color: #F86262;
    }
  }
  .width-100{
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    .van-cell{
      padding: 0;
    }
  }
}
.boxstyle {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.bottombutton{
  margin: 10px 20px;
  width:90%;
  height: 40px;
}
.van-cell{
  padding-left: 0;
  padding-right: 0;
}
.van-hairline--bottom.van-hairline--bottom::after{
  border: 0;
}
.van-cell:not(:last-child)::after{
  left: 0;
}
</style>
<style lang="scss">
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
.van-nav-bar {
  height: 80px;
}
</style>
